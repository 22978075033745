<template>
    <div class="mobile-view">
        <div class="logo--wrapper" v-bind:style="{ backgroundColor: appColor }">
            <v-icon :color="textColor" size="18">mdi-cog</v-icon>
            <Logo light width="136" height="30"></Logo>
            <v-icon :color="textColor" size="18">mdi-logout</v-icon>
        </div>
        <div class="form--wrapper">
            <div class="grey--line"></div>
            <div class="flex justify-center align-center form">
                <input class="form-control" type="text" placeholder="LOGIN"><br>
                <input class="form-control" type="text" placeholder="PASSWORD"><br><br>
                <a class="btn btn-primary btn-block">LOGIN WITH EMAIL/PASSWORD</a><br><br>
                <a class="btn btn-primary btn-block">LOGIN WITH QRCODE</a><br><br>
                <span>Reset Password</span>
            </div>
            <div class="grey--line">KOIOS MOBILE v.2.2</div>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/widgets/Logo';

export default {
    name: 'MobileAppDemo',
    props: {
        appColor: {
            type: String,
            'default': ''
        },
        textColor: {
            type: String,
            'default': ''
        }
    },
    components: { Logo }
};
</script>

<style scoped>
    .mobile-view {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        width: 300px;
        height: 440px;
        margin: 0 auto;
        border: 1px solid black;
    }

    .logo--wrapper {
        background-color: #2D2D2C;
        display: flex;
        padding: 0 10px;
        justify-content: space-between;
    }
    .grey--line {
        text-align: center;
        background-color: #b2b2b2;
        height: 18px;
        font-size: 10px;
        padding: 2px 0;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        flex: 1 1 300px;
    }
    .form--wrapper {
        display: flex;
        height: 408px;
        flex-wrap: wrap;
        align-content: space-between;
    }
    .form {
        margin: 50px 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    .btn-block {
        display: block;
        width: 100%;
    }
    .btn-primary {
        color: #fff !important;
        background-color: #337ab7;
        border-color: #2e6da4;
    }
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-radius: 4px;
    }
    a {
        color: #337ab7;
        display: block;
        text-decoration: none;
    }
    span {
        text-align: right;
        width: 100%;
        display: block;
        color: #337ab7;
    }
</style>
