<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <DefaultTable v-if="canRead"
                      :setup="tableSetup"
                      @edit-item="openForm($event)"
                      @delete-item="confirmDeleteItem($event)"
                      ref="agenciesTable">
            <template #search>
                <v-col cols="12" md="6">
                    <Autocomplete label="assigned person"
                                  :items="responsibleEmployees"
                                  item-text="text"
                                  item-value="value"
                                  clearable
                                  name="person_assigned"
                                  v-model="personAssigned">
                    </Autocomplete>
                </v-col>
            </template>
            <template v-slot:item.isActive="{ item }">
                <v-simple-checkbox
                    :value="!!item.isActive"
                    disabled
                ></v-simple-checkbox>
            </template>
            <template v-slot:item.isDemo="{ item }">
                <v-simple-checkbox
                    :value="!!item.isDemo"
                    disabled
                ></v-simple-checkbox>
            </template>
            <template v-slot:item.lastLogin="{ item }">
                {{ item.lastLogin ? item.lastLogin.split('+')[0].split('T').join(' ') : ''}}
            </template>
        </DefaultTable>
        <div v-else class="d-flex justify-center align-center h-full">
            <h2>You are not authorized to view this page</h2>
        </div>
        <v-dialog v-model="dialog" :persistent="true">
            <v-sheet outlined height="68vh" class="d-flex flex-column justify-center align-center" v-if="loader">
                <v-progress-circular
                    :size="160"
                    :width="7"
                    indeterminate
                >creating an agency</v-progress-circular>
                <v-btn color="secondary"
                       outlined
                       class="mt-10"
                       @click="closeForm">Close
                </v-btn>
            </v-sheet>
            <SuperAdminManageAgencyForm v-if="!loader"
                @create="createItem"
                @save="saveItem"
                @close="closeForm">
            </SuperAdminManageAgencyForm>
        </v-dialog>
        <v-dialog
            v-model="confirmationDialog"
            persistent
            max-width="420"
        >
            <v-card>
                <v-card-title v-show="!deleteLoader &&!showDeleteInfo">
                    Confirm database name to delete
                </v-card-title>
                <v-card-text class="pt-10">
                    <TextField v-show="!deleteLoader &&!showDeleteInfo"
                        v-model="dbName"
                        name="database name"
                        label="database name"
                    ></TextField>
                    <div class="d-flex justify-center align-center">
                        <v-progress-circular v-show="deleteLoader"
                                             :size="120"
                                             :width="7"
                                             indeterminate
                        >removal...
                        </v-progress-circular>
                        <template v-if="showDeleteInfo">
                            <p v-show="deleteStatusOk">the agency has been successfully removed</p>
                            <p v-show="!deleteStatusOk" class="red--text">agency removal failed</p>
                        </template>
                    </div>
                </v-card-text>
                <v-layout align-center justify-center class="py-5">
                    <v-card-actions>
                        <v-btn v-show="!deleteLoader &&!showDeleteInfo"
                            color="secondary"
                            outlined
                            class="mr-3"
                            @click="cancelDelete">
                            Cancel
                        </v-btn>
                        <v-btn v-show="showDeleteInfo"
                            color="secondary"
                            outlined
                            class="mr-3"
                            @click="cancelDelete">
                            Close
                        </v-btn>
                        <v-btn v-show="!deleteLoader &&!showDeleteInfo"
                            :disabled="disableDelete"
                            color="secondary"
                            class="mr-3"
                            @click="deleteItem">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DefaultTable from '@/components/DefaultTable';
import SuperAdminManageAgencyForm from '@/components/superadmin/SuperAdminManageAgencyForm';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextField from '@/components/widgets/forms/TextField';
import debounce from 'lodash.debounce';

export default {
    name: 'SuperAdminAgencies',
    components: { DefaultTable, Autocomplete, SuperAdminManageAgencyForm, TextField },
    data: () => ({
        responsibleEmployees: [],
        personAssigned: null,
        dialog: false,
        confirmationDialog: false,
        loader: false,
        deleteLoader: false,
        showDeleteInfo: false,
        deleteStatusOk: false,
        itemToDelete: null,
        dbName: ''
    }),
    computed: {
        canRead () {
            return this.$can(this.$abilityActions.READ, this.$abilitySubjects.SUPER_ADMIN_AGENCY);
        },
        tableSetup () {
            return {
                title: 'Agencies',
                searchBy: 'name',
                headers: [
                    {
                        text: 'No',
                        value: 'seq',
                        sortable: false
                    },
                    {
                        text: 'id',
                        value: 'id',
                        sortable: true
                    },
                    {
                        text: 'Agency name',
                        value: 'name',
                        sortable: true
                    },
                    {
                        text: 'DB name',
                        value: 'database_name',
                        sortable: true
                    },
                    {
                        text: 'Language',
                        value: 'language',
                        sortable: true
                    },
                    {
                        text: 'Assigned person',
                        value: 'user_resp',
                        sortable: true
                    },
                    {
                        text: 'Last login (utc)',
                        value: 'lastLogin',
                        sortable: true
                    },
                    {
                        text: 'Demo',
                        value: 'isDemo',
                        sortable: true
                    },
                    {
                        text: 'Active',
                        value: 'isActive',
                        sortable: true
                    },
                    {
                        text: 'Operations',
                        value: 'actions',
                        sortable: false,
                        width: '150px'

                    }
                ],
                endpoint: 'superadmin_agencies',
                itemClass: this.additionalClass,
                ability: {
                    c: this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.SUPER_ADMIN_AGENCY),
                    r: false,
                    u: this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SUPER_ADMIN_AGENCY),
                    d: this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.SUPER_ADMIN_AGENCY)
                },
                sortable: {
                    id: 'desc',
                    name: 'asc',
                    database_name: 'asc',
                    language: 'asc',
                    user_resp: 'asc',
                    lastLogin: 'asc',
                    isDemo: 'asc',
                    isActive: 'asc'
                }
            };
        },
        additionalParams () {
            if (this.personAssigned) {
                return {
                    'filter[id_user_resp]': this.personAssigned
                };
            } else return null;
        },
        disableDelete () {
            return this.itemToDelete?.database_name !== this.dbName;
        }
    },
    methods: {
        deleteStatusClose: debounce(function () {
            this.cancelDelete();
            this.closeForm();
            this.deleteStatusOk = false;
        }, 3000),
        additionalClass (item) {
            if (item.todelete === 1) {
                return 'expired';
            }
        },
        openForm (item) {
            if (item) {
                this.$store.dispatch('superadmin/getAgency', item.id);
            }
            this.dialog = true;
        },
        closeForm () {
            this.dialog = false;
            this.loader = false;
            this.$store.commit('superadmin/SET_DEFAULT_AGENCY');
            this.$refs.agenciesTable.fetchData();
        },
        createItem () {
            this.loader = true;
            this.$store.dispatch('superadmin/createAgency').then(() => {
                this.closeForm();
            });
        },
        saveItem () {
            this.loader = true;
            this.$store.dispatch('superadmin/updateAgency').then(() => {
                this.closeForm();
            });
        },
        confirmDeleteItem (item) {
            this.loader = true;
            this.confirmationDialog = true;
            this.itemToDelete = item;
        },
        deleteItem () {
            this.deleteLoader = true;
            this.$store.dispatch('superadmin/deleteAgency', this.itemToDelete.id).then((resp) => {
                this.deleteLoader = false;
                if (resp.status === 200) {
                    this.showDeleteInfo = true;
                    this.deleteStatusOk = true;
                } else this.deleteStatusOk = false;
                this.deleteStatusClose();
            });
        },
        cancelDelete () {
            this.confirmationDialog = false;
            this.showDeleteInfo = false;
            this.itemToDelete = null;
            this.dbName = '';
        }
    },
    watch: {
        personAssigned: {
            handler (val) {
                if (val !== null) {
                    this.$refs.agenciesTable.setup.fetchParams = this.additionalParams;
                    this.$refs.agenciesTable.fetchData();
                } else {
                    this.$refs.agenciesTable.setup.fetchParams = null;
                    this.$refs.agenciesTable.fetchData();
                }
            },
            deep: true
        }
    },
    created () {
        const params = {
            'page[number]': -1
        };
        if (this.canRead) {
            this.$store.dispatch('superadmin/getUsers', params).then(() => {
                this.responsibleEmployees = this.$store.state.superadmin.users.map(e => {
                    return {
                        text: `${e.attributes.name || 'no name'}(${e.attributes.login})`,
                        value: e.id
                    };
                });
            });
        }
    }
};
</script>
<style scoped>
    .h-full {
        height: 100vh;
    }
</style>
