<template>
    <div>
        <v-file-input :rules="rules"
                      v-if="!image"
                      v-bind:value="image"
                      v-on:input="$emit('input', $event.target.value)"
                      accept="image/png, image/jpeg, image/bmp"
                      :label="text"
                      show-size
                      prepend-icon="mdi-camera"
                      @click:clear="clearImage"
                      @change="onFileChange">
        </v-file-input>
        <v-icon v-if="imageSrc !== imageUrl" @click="clearImage()">mdi-trash-can-outline</v-icon>
        <v-img max-width="100%"
               max-height="100"
               contain
               :src="imagePath">
        </v-img>
    </div>
</template>

<script>

export default {
    name: 'ImageInput',
    props: {
        imageUrl: {
            type: String,
            'default': ''
        },
        text: {
            type: String,
            'default': ''
        }
    },
    data: () => ({
        rules: [value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!'],
        imageSrc: '',
        image: null
    }),
    computed: {
        imagePath () {
            if (this.imageSrc) {
                return this.imageSrc;
            } else if (this.imageUrl) {
                return `data:image/png;base64,${this.imageUrl}`;
            } else return null;
        }
    },
    methods: {
        clearImage () {
            this.image = null;
            this.imageSrc = '';
            this.$emit('delete');
        },
        createImage (file) {
            const reader = new FileReader();

            reader.onload = e => {
                this.imageSrc = e.target.result;
                this.$emit('new-file', e.target.result);
            };
            reader.readAsDataURL(file);
        },
        onFileChange (file) {
            if (!file) {
                return;
            }
            this.createImage(file);
        }
    }
};
</script>
