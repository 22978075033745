<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card flat>
        <v-card-title>Agency edit
            <v-layout justify-end>
                <v-btn color="primary" outlined class="ml-4" @click="close">cancel</v-btn>
                <v-btn color="primary" class="ml-4" @click="save">save</v-btn>
            </v-layout>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4">
                    <v-card elevation="0" class="my-5" height="100%">
                        <v-card-title>
                            Main data
                        </v-card-title>
                        <v-card-text class="mt-4">
                            <TextField :label="$t('labels.agency_name')"
                                       v-model="name"></TextField>
                            <TextField :label="$t('labels.db_name')" disabled
                                       v-model="database_name"></TextField>
                            <SelectField :label="$t('labels.responsible_person')"
                                         :items="responsibleEmployees"
                                         v-model="id_user_resp"></SelectField>
                            <SelectField :label="$t('labels.language')"
                                       :items="languages"
                                         v-model="language"></SelectField>
                            <TextField :label="$t('labels.def_lat')"
                                       v-model="defLat"></TextField>
                            <TextField :label="$t('labels.def_lon')"
                                       v-model="defLon"></TextField>

                            <Checkbox v-model="isActive"
                                      :false-value="0"
                                      :true-value="1"
                                      class="d-flex"
                                      :label="$t('labels.active')"></Checkbox>
                            <Checkbox v-model="todelete"
                                      class="d-flex"
                                      :false-value="0"
                                      :true-value="1"
                                      :label="$t('labels.to_delete')">
                            </Checkbox>
                            <Checkbox v-model="isDemo"
                                      :false-value="0"
                                      :true-value="1"
                                      class="mt-0"
                                      :label="$t('labels.demo')">
                            </Checkbox>
                            <DatePicker :label="$t('labels.demo_date')"
                                        v-if="isDemo"
                                        clearable

                                        class-name="pt-4"
                                        v-model="demoDate">
                            </DatePicker>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card elevation="0" class="my-5">
                                <v-card-title>
                                    External modules link
                                </v-card-title>
                                <v-card-text class="mt-4">
                                    <Checkbox v-model="useKronos"
                                              class="d-flex"
                                              :false-value="0"
                                              :true-value="1"
                                              label="Kronos NET link">
                                    </Checkbox>
                                    <Checkbox v-model="useSmsApi"
                                              class="d-flex"
                                              :false-value="0"
                                              :true-value="1"
                                              label="SMSAPI sms gateway">
                                    </Checkbox>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card elevation="0" class="my-5">
                        <v-card-title>
                            Application style
                        </v-card-title>
                        <v-card-text class="mt-4">
                            <MobileAppDemo :app-color="mobileColor" :text-color="mobileTextColor"></MobileAppDemo>
                            <v-row>
                                <v-col cols="6">Application color
                                    <v-menu offset-y :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-btn block :color="mobileColor" dark v-on="on"></v-btn>
                                        </template>
                                        <v-color-picker
                                            min-width="390px"
                                            v-model="mobileColor"
                                            hide-canvas
                                            show-swatches
                                            class="mx-auto"
                                        ></v-color-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">Text color
                                    <v-menu offset-y
                                            :close-on-click="true"
                                            :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-btn block :color="mobileTextColor" dark v-on="on"></v-btn>
                                        </template>
                                        <v-color-picker
                                            min-width="390px"
                                            v-model="mobileTextColor"
                                            hide-canvas
                                            show-swatches
                                            class="mx-auto"
                                        ></v-color-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-btn color="primary" outlined class="mb-12" @click="resetColor">Reset colors</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                    <v-row class="mt-12 mx-auto" justify="center">
                        <v-col cols="12" md="3">
                            <ImageInput :imageUrl="print"
                                        text="PRINT LOGO"
                                        @new-file="print = $event"
                                        @delete="print = ''">
                            </ImageInput>
                        </v-col>
                        <v-col cols="12" md="3">
                            <ImageInput :imageUrl="watermark"
                                        text="WATERMARK"
                                        @new-file="watermark = $event"
                                        @delete="watermark = ''">
                            </ImageInput>
                        </v-col>
                        <v-col cols="12" md="3">
                            <ImageInput :imageUrl="web"
                                        text="WEB"
                                        @new-file="web = $event"
                                        @delete="web = ''">
                            </ImageInput>
                        </v-col>
                        <v-col cols="12" md="3">
                            <ImageInput :imageUrl="mobile"
                                        text="MOBILE"
                                        @new-file="mobile = $event"
                                        @delete="mobile = ''">
                            </ImageInput>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TextField from '@/components/widgets/forms/TextField';
import ImageInput from '@/components/widgets/forms/ImageInput';
import SelectField from '@/components/widgets/forms/SelectField';
import Checkbox from '@/components/widgets/forms/Checkbox';
import DatePicker from '@/components/widgets/forms/DatePicker';
import MobileAppDemo from '@/components/superadmin/MobileAppDemo';

export default {
    name: 'SuperAdminManageAgencyForm',
    components: { ImageInput, TextField, SelectField, Checkbox, DatePicker, MobileAppDemo },
    data: () => ({
        languages: ['pl-PL', 'en-GB', 'es-ES', 'uk-UA', 'ru-RU', 'sl-SI']
    }),
    computed: {
        responsibleEmployees () {
            return this.$store.state.superadmin.users.map(e => {
                return {
                    text: `${e.attributes.name || 'no name'}(${e.attributes.login})`,
                    value: parseInt(e.id)
                };
            });
        },
        ...mapFields('superadmin', [
            'agency.id',
            'agency.name',
            'agency.database_name',
            'agency.language',
            'agency.useKronos',
            'agency.isActive',
            'agency.useSmsApi',
            'agency.isDemo',
            'agency.demoDate',
            'agency.id_user_resp',
            'agency.todelete',
            'agency.defLat',
            'agency.defLon',
            'agency.logos.print',
            'agency.logos.watermark',
            'agency.logos.web',
            'agency.logos.mobile',
            'agency.mobileColor',
            'agency.mobileTextColor'
        ])
    },
    watch: {
        mobileColor: {
            handler (val) {
                if (val === '') {
                    this.mobileColor = '#000000';
                }
            },
            deep: true,
            immediate: true
        },
        mobileTextColor: {
            handler (val) {
                if (val === '') {
                    this.mobileTextColor = '#FFFFFF';
                }
            },
            immediate: true,
            deep: true
        },
        demoDate: {
            handler (val) {
                if (val === 'Invalid DateTime') {
                    this.demoDate = null;
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        save () {
            if (this.id) {
                this.$emit('save');
            } else {
                this.$emit('create');
            }
        },
        resetColor () {
            this.mobileColor = '#000000';
            this.mobileTextColor = '#FFFFFF';
        }
    }

};
</script>
